// Helper functions for handling publications
// (has mjs extension to allow use in scripts, bummer)

export function versionsByLang(documents) {
  // group by language
  const r = documents.reduce(function (r, d) {
    if (!r[d.lang]) r[d.lang] = [];
    r[d.lang].push(d);
    return r;
  }, {});

  // sort versions, highest version first
  Object.values(r).forEach(function (docs) {
    docs.sort(function (a, b) {
      return b.version.localeCompare(a.version, undefined, { numeric: true });
    });
  });

  return r;
}

// return all version numbers, highest version first
export function versions(documents) {
  const versions = documents.map((doc) => doc.version).filter((d) => d);
  versions.sort(function (a, b) {
    return b.localeCompare(a, undefined, { numeric: true });
  });
  return versions;
}

export function documentExt(document) {
  return /\.[^.]+$/.exec(document.doc.url)?.at(0)?.toLowerCase();
}

export function documentFilename(uid, document, versioned = true) {
  const ext = documentExt(document);
  const _version = versioned && document.version ? `-v${document.version}` : '';
  return `${uid}${_version}.${document.lang}${ext}`;
}

export function documentLink(uid, document, versioned = true) {
  return `/download/${documentFilename(uid, document, versioned)}`;
}

// based on https://stackoverflow.com/a/20459666 and https://programanddesign.com/js/human-readable-file-size-in-javascript/
export function humanSize(size, options = {}) {
  const base = options.base || 1000;
  const precision = options.precision || 0;
  const i = Math.floor(Math.log(size) / Math.log(base));
  const j = (size / Math.pow(base, i)).toFixed(precision) * 1;
  const unit = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'][i];
  return j + ' ' + unit;
}
