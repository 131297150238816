// Base slice wrapper component for consistent spacing
import clsx from 'clsx';

export const BaseSlice = ({ className, children }) => (
  <div className={clsx('py-lg-4 py-3', className)}>{children}</div>
);

export const HeroSlice = ({ className, children }) => (
  <div className={clsx('py-6', className)}>{children}</div>
);
