import { PrismicRichText } from '@prismicio/react';
import { Heading } from './Heading';
import clsx from 'clsx';

export function RichText({ components, mb, large, ...rest }) {
  let _mb = mb || 3;
  return (
    <PrismicRichText
      components={{
        heading1: ({ children }) => (
          <Heading as="h1" className={`mb-${_mb + 1}`}>
            {children}
          </Heading>
        ),
        heading2: ({ children }) => (
          <Heading as="h2" className={`mb-${_mb}`}>
            {children}
          </Heading>
        ),
        paragraph: ({ children }) => (
          <p className={clsx(`mb-${_mb}`, large && 'text-large')}>{children}</p>
        ),
        ...components
      }}
      {...rest}
    />
  );
}
