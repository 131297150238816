// Keep in sync with publication's Main.documents.config.fields.lang.config.options

const LANGUAGES = {
  en: {
    name: 'English',
    flag: '🇬🇧'
  },
  nl: {
    name: 'Dutch',
    flag: '🇳🇱'
  },
  de: {
    name: 'German',
    flag: '🇩🇪'
  },
  fr: {
    name: 'French',
    flag: '🇫🇷'
  },
  se: {
    name: 'Swedish',
    flag: '🇸🇪'
  }
};

export default LANGUAGES;
