export default function DocumentIcon({ ...props }) {
  return (
    <svg
      width="196"
      height="253"
      viewBox="0 0 196 253"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <title>document</title>
      <g transform="matrix(2.81,0,0,2.81,-50.65587,0)">
        <path
          d="M 87.822,26.164 V 84.683 C 87.822,87.62 85.441,90 82.505,90 H 23.344 c -2.937,0 -5.317,-2.381 -5.317,-5.317 V 71.549 5.317 C 18.027,2.381 20.407,0 23.344,0 h 38.315 c 8.269,0.135 26.163,16.011 26.163,26.164 z"
          fill="#e2e2e2"
          strokeLinecap="round"
        />
        <path
          d="m 61.659,0 h 6.662 c 2.826,0 5.536,1.123 7.534,3.121 l 8.847,8.847 c 1.998,1.998 3.121,4.708 3.121,7.534 v 6.662 c 0,-3.419 -2.772,-6.19 -6.19,-6.19 h -7.866 c -3.268,0 -5.917,-2.649 -5.917,-5.917 0,0 0,-7.866 0,-7.866 v 0 C 67.849,2.772 65.078,0 61.659,0 c 0,0 0,0 0,0 z"
          fill="#b7b7b7"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
